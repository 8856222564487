
export class PwThemeModel {
    public borderColor: string;
    public textColor: string;
    public buttonBgColor: string;
    public buttonTextColor: string;
    public labelColor: string;
    public errorColor: string;
    public borderRadius?: string;
    public dropdownOptionBgColor?: string;
    public dropdownOptionTextColor?: string;
    public buttonBorderColor?: string;
    public borderSize?: string;
    public campaignTemplateBackPageColor?: string;

    constructor(args: PwThemeModel) {
        for(const key in args){
            this[key] = args[key];
        }
    }
}