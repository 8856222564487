<div class="upload-data__container">
    <div class="upload-data__controls-wrapper" [ngClass]="{'opaque': dataUploadResponses}">

        <div class="">
            <pw-input [data]="{name: 'batchName', label: 'Batch Name:', placeholder: '1life-funeral 2020'}" [parentFormGroup]="uploadNewBatchForm" [formStatus]="formStatus"></pw-input>
            <pw-input *ngIf="data.hasDataSource" [data]="{name: 'batchSource', label: 'Data Source Name:', placeholder: 'Motloung Technology Group'}" [parentFormGroup]="uploadNewBatchForm" [formStatus]="formStatus"></pw-input>
        </div>
        
        <p>Number of records in batch: {{ noOfRecords || 0 }}</p>
        <p class="upload-data__warning">Max number of records: {{data.max || 500}}</p>

        <div [formGroup]="uploadNewBatchForm" class="excel-sheet">
            <input name="sheet" formControlName="sheet" type="file" (change)="getExcelSheet($event)">
        </div>

        <div class="upload-button">
            <pw-button [data]="{label: 'Upload', disabled: isDisabled}" (click)="uploadNewBatch()"></pw-button>
        </div>
    </div>

    <hr *ngIf="dataUploadResponses">

    <div class="" *ngIf="dataUploadResponses">
        <h3> Upload status: </h3>
        
        <p><b>Success: </b> {{dataUploadResponses.success}} ({{ dataUploadResponses.successPerc }}%) </p>
        <p><b>Duplicates: </b> {{dataUploadResponses.duplicate}} ({{ dataUploadResponses.duplicatePerc }}%) </p>
        <p><b>Error/other: </b> {{dataUploadResponses.other}} ({{ dataUploadResponses.otherPerc }}%) </p>
    </div>

    <div class="loading" *ngIf="isLoading">
        <h2>Loading...</h2>
        <p>This may take up to 15 minutes for data sets greater than 5000</p>
    </div>

    <div class="footer">
        &copy; Copyright {{ year }} Kaleva Media (Pty) Ltd.
    </div>
</div>