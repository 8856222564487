import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PwDropdownService {

  @Output() dropdownIsReset: EventEmitter<any> = new EventEmitter();

  constructor() { }

  resetDropdown(ctrlName: string) {
    this.dropdownIsReset.emit({reset: true, ctrlName: ctrlName});
  }
}
