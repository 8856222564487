import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/Services/auth.service';
@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  email:string = '';
  password: string = '';
  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit(): void {
  }
  LoginUser(){
     this.auth.login(this.email, this.password);
  }
  navigateToCreateAccount(){
    this.router.navigate(['/signup']);
  }

}
