import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwTextAreaComponent } from './pw-text-area.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    PwTextAreaComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
   exports: [
    PwTextAreaComponent
   ]
})
export class PwTextAreaModule { }
