import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms'
import { AuthService } from '../Services/auth.service';
import { Router } from '@angular/router';
import { AlertService } from '../Services/alert.service';
@Component({
  selector: 'sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  showPassword: boolean | undefined;
  showPasswordOnPress: boolean | undefined;
  showPasswordReType: boolean | undefined;
  showPasswordOnPressReType: boolean | undefined;
  createUser = new FormGroup({
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    email: new FormControl(''),
    ReEmail: new FormControl(''),
    password: new FormControl(''),
    Repassword: new FormControl(''),
    mobileNumber: new FormControl(''),
    companyName: new FormControl(''),
    companyRegistrationNo: new FormControl(''),
  });
  constructor(private _auth: AuthService, private router: Router,private _alertService: AlertService) { }

  ngOnInit(): void {}
  
  registerUser(){
    let user = {
      firstName: this.createUser.value['firstName'],
      lastName: this.createUser.value['lastName'],
      email: this.createUser.value['email'],
      mobileNumber: this.createUser.value['mobileNumber'],
      companyName: this.createUser.value['companyName'],
      companyRegistrationNo: this.createUser.value['companyRegistrationNo']
    }
    if (user.firstName !== '' && user.lastName !== '' && user.email !== '' && user.mobileNumber !== '' && user.companyName !== '' && user.companyRegistrationNo !== '') {
      if(this.createUser.value['password'] != this.createUser.value['Repassword']){
        this._alertService.notificationToast('warning',"passwords do not match!");
      }
      else{
        this._auth.signup(this.createUser.value['email'],this.createUser.value['password'],user);
      }
    } else {
      this._alertService.notificationToast('warning',"please enter all fields!");
    }
  }
  navigateToLogin(){
    this.router.navigate(['/login']);
  }

}
