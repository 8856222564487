import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'pw-segmented-control',
  templateUrl: './pw-segmented-control.component.html',
  styleUrls: ['./pw-segmented-control.component.scss']
})
export class PwSegmentedControlComponent implements OnInit {

  @Input() data: any;
  @Input() parentFormGroup: FormGroup;
  constructor() { }

  ngOnInit(): void {
  }

  selectOption(option: any) {
    this.parentFormGroup.get(this.data.name).setValue(option.value);
    this.data.options.map((opt) => {
      opt.active = false;
    });
    option.active = true;
  }

}
