import { Injectable } from '@angular/core';
import { PwThemeModel } from './theme-config.model';

@Injectable({
  providedIn: 'root'
})
export class PwThemeingService {

  defaultTheme: PwThemeModel = this.getDefaultTheme();

  constructor() { }

  setTheme(themeConfig: PwThemeModel) {
    themeConfig.borderColor = themeConfig.borderColor ? themeConfig.borderColor : themeConfig.borderColor;
    document.documentElement.style.setProperty('--pw-border-color', themeConfig.borderColor);
    
    themeConfig.textColor = themeConfig.textColor ? themeConfig.textColor : this.defaultTheme.textColor;
    document.documentElement.style.setProperty('--pw-text-color', themeConfig.textColor);
    
    themeConfig.buttonBgColor = themeConfig.buttonBgColor ? themeConfig.buttonBgColor : this.defaultTheme.buttonBgColor;
    document.documentElement.style.setProperty('--pw-button-background-color', themeConfig.buttonBgColor);
    
    themeConfig.buttonTextColor = themeConfig.buttonTextColor ? themeConfig.buttonTextColor : this.defaultTheme.buttonTextColor;
    document.documentElement.style.setProperty('--pw-button-text-color', themeConfig.buttonTextColor);
    
    themeConfig.labelColor = themeConfig.labelColor ? themeConfig.labelColor : this.defaultTheme.labelColor;
    document.documentElement.style.setProperty('--pw-label-color', themeConfig.labelColor);
    
    themeConfig.errorColor = themeConfig.errorColor ? themeConfig.errorColor : this.defaultTheme.errorColor;
    document.documentElement.style.setProperty('--pw-error-color', themeConfig.errorColor);

    themeConfig.borderRadius = themeConfig.borderRadius ? themeConfig.borderRadius : this.defaultTheme.borderRadius;
    document.documentElement.style.setProperty('--pw-border-radius', themeConfig.borderRadius);
    
    themeConfig.dropdownOptionBgColor = themeConfig.dropdownOptionBgColor ? themeConfig.dropdownOptionBgColor : this.defaultTheme.dropdownOptionBgColor;
    document.documentElement.style.setProperty('--pw-dropdown-option-bg-color', themeConfig.dropdownOptionBgColor);
    
    themeConfig.dropdownOptionTextColor = themeConfig.dropdownOptionTextColor ? themeConfig.dropdownOptionTextColor : this.defaultTheme.dropdownOptionTextColor;
    document.documentElement.style.setProperty('--pw-dropdown-option-text-color', themeConfig.dropdownOptionTextColor);
    
    themeConfig.buttonBorderColor = themeConfig.buttonBorderColor ? themeConfig.buttonBorderColor : this.defaultTheme.buttonBorderColor;
    document.documentElement.style.setProperty('--pw-button-border-color', themeConfig.buttonBorderColor);
    
    themeConfig.borderSize = themeConfig.borderSize ? themeConfig.borderSize : this.defaultTheme.borderSize;
    document.documentElement.style.setProperty('--pw-border-size', themeConfig.borderSize);
    
    themeConfig.campaignTemplateBackPageColor = themeConfig.campaignTemplateBackPageColor ? themeConfig.campaignTemplateBackPageColor : this.defaultTheme.campaignTemplateBackPageColor;
    document.documentElement.style.setProperty('--pw-backpage-bg-color', themeConfig.campaignTemplateBackPageColor);
  }

  getDefaultTheme(): PwThemeModel {
    let defaultTheme = new PwThemeModel({
      borderColor: 'black',
      textColor: 'black',
      buttonBgColor: 'white',
      buttonTextColor: 'black',
      labelColor: 'black',
      errorColor: 'red',
      borderRadius: '5px',
      dropdownOptionBgColor: 'white',
      dropdownOptionTextColor: 'black',
      buttonBorderColor: 'black',
      borderSize: '2px',
      campaignTemplateBackPageColor: 'black'
    })
    return defaultTheme;
  }
}
