import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { Subject } from 'rxjs';
import { AlertService } from '../Services/alert.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private loggedInUser: any = undefined;
  private loggedInuserId: string;
  public logginInUser_bsub = new BehaviorSubject(this.loggedInUser);
  private loggedInUserSubject = new Subject<string>();
  loggedInUser$ = this.loggedInUserSubject.asObservable();
  constructor(private _alertService: AlertService,private auth: AngularFireAuth, private _router: Router, private _db: AngularFirestore) { }
  
  public login(email: any, password: any): Promise<any> {
    let loggedIN = this.auth.signInWithEmailAndPassword(email, password).then((data)=>{
      this.setLoggedInUserId(email);
      if (typeof sessionStorage["usr"] != 'undefined') {
        this.getUserByID(JSON.parse(sessionStorage["usr"]).value).then((user:any)=>{
          this.loggedInUserSubject.next(user);
          if(JSON.parse(sessionStorage["usr"]).value.admin)
          {
            this._alertService.notificationToast('success',`Welcome Back, You have Admin rights!`);
          }
          else{
            this._alertService.notificationToast('success',`Login accepted Welcome to hotleads!`);
          }
          this._router.navigate(['campaigns'], { queryParams: { userLoggedIn: user } });
        })
      }
    }).catch(err=>{
      this._alertService.notificationToast('warning',err.code);
    });
    return loggedIN;
  }

  public signup(email: any, password: any, agent: any): Promise<any> {
    let returnValue = this.auth.createUserWithEmailAndPassword(email, password).then(() => {
      this.addUser(agent);
      this.loggedInUserSubject.next(agent);
      
      this._router.navigate(['campaigns'], { queryParams: { userLoggedIn: agent } });
    }).catch(err => {
      this._alertService.notificationToast('warning',`Registration failed!, ${err.message}`);
    });
    return returnValue;
  }

  public setLoggedInUserId(id: string) {
    this.loggedInuserId = id;
    this.sessionSet('usr', this.loggedInuserId,120);
  }

  sessionSet(key, value, expirationInMin) {
    let expirationDate = new Date(new Date().getTime() + (60000 * expirationInMin))
      let newValue = {
      value: value,
      expirationDate: expirationDate.toISOString()
    }
    window.sessionStorage.setItem(key, JSON.stringify(newValue));
  }

  addUser(agent: any) {
    this._alertService.notificationToast('success',`You have successfully created an account!`);
    this._db.collection("clients").doc(agent.email).set(agent);
  }

  getUserByID(emailAddress: string){
    const users= this._db.collection('clients').valueChanges();
    let promise = new Promise((resolve, reject) => {
      users.subscribe(user=>{
        user.forEach((element: any) => {
            if(element.email === emailAddress){
               resolve(element);
            }
         });
        });
    });
    return promise;
  }
  
  sessionGet(key) {
    let stringValue = window.sessionStorage.getItem(key)
      if (stringValue !== null) {
        let value = JSON.parse(stringValue)
          let expirationDate = new Date(value.expirationDate)
          if (expirationDate > new Date()) {
            return value.value
          } else {
            window.sessionStorage.removeItem(key)
          }
      }
      return null
  }

  public getLoggedInUserId() {
    return this.sessionGet('usr');
  }

  public isAuthenticated(): boolean {
    if (!sessionStorage["usr"]) {
      return false;
    }
    return true;
  }

}
