import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class AlertService {
   Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
  });
  constructor() {}
  notificationToast(type,message){
    this.Toast.fire({
      icon: type,
      title: message
    });
  }
  formattedName(name: string) {
    return _.startCase(_.replace(name, '-', ' '));
  }

}
