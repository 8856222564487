<div class="submit-lead" [ngClass]="{'submit-lead-orange': data.theme === 'orange', 'submit-lead-green': data.theme === 'green'}">
	<div class="header">
		<img src="" />
	</div>

	<div class="body" [ngClass]="{'flip': showMessage, 'back': showMessage}">
		<div *ngIf="!showMessage">

            <div class="inputs-wrapper" *ngFor="let input of data.inputs">
                <pw-input *ngIf="input.type === 'input'" [data]="{name: input.name, label: input.label, type: input.inputType}" [parentFormGroup]="campaignFormGroup" [formStatus]="formStatus"></pw-input>

                <pw-dropdown *ngIf="input.type === 'dropdown'" [data]="{name: input.name, label: input.label, options: input.options, placeholder: input.placeholder}" [parentFormGroup]="campaignFormGroup"></pw-dropdown>
                
				<pw-textarea *ngIf="input.type === 'textarea'" [data]="{name: input.name, label: input.label, cols: input.cols, rows: input.rows}" [parentFormGroup]="campaignFormGroup" [formStatus]="formStatus"></pw-textarea>
            </div>

			<pw-button [data]="{name: 'submit', label: 'Submit', disabled: false}" (click)="submitLead()"></pw-button>
		</div>

		<div class="back message" *ngIf="showMessage">
			<div class="message__heading">
				{{ messageTitle }}
			</div>

			<div class="message__message">
				{{ messageBody }}
			</div>
		</div>

	</div>

	<div class="reset" *ngIf="showMessage" >
		<pw-button [data]="{name: 'ok', label: 'Ok', disabled: false}" (click)="resetForm()"></pw-button>
	</div>

	<div class="footer">
		&copy; Copyright {{ year }} Kaleva Media (Pty) Ltd.
	</div>
</div>