import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PwDropdownService } from './pw-dropdown.service';

@Component({
  selector: 'pw-dropdown',
  templateUrl: './pw-dropdown.component.html',
  styleUrls: ['./pw-dropdown.component.scss']
})
export class PwDropdownComponent implements OnInit {
  
  @Input() data: any;
  @Input() parentFormGroup: FormGroup;
  selectedOption: any;
  isExpanded: boolean = false;
  placeholder: string;

  constructor(private dropdownService: PwDropdownService) { }

  ngOnInit() {
    this.placeholder = this.data.placeholder ? this.data.placeholder : 'Select';
    this.dropdownService.dropdownIsReset.subscribe((resetObj) => {
      if (resetObj.reset) {
        this.parentFormGroup.controls[resetObj.ctrlName].setValue(null);
        this.selectedOption = null;
        this.placeholder = this.data.placeholder ? this.data.placeholder : 'Select';
      }
    });
    this.findDefaultOption(this.data.options);
  }

  selectOption(option) {
    this.selectedOption = option;
    this.placeholder = this.selectedOption.label;
    this.isExpanded = false;
    this.parentFormGroup.controls[this.data.name].setValue(this.selectedOption);
  }

  findDefaultOption(options: any[]) {
    let selectedOption = options.find(option => option.selected === true);
    this.selectOption(selectedOption);
    return selectedOption;
  }
}
