<mat-toolbar *ngIf="navigationIsVisible && loggedInUser && !isHomePageOrLoginPage()" class="navigation__header mat-toolbar mat-toolbar-single-row">
    <div class="toolbar-content">
      <a routerLink="/campaigns" class="logo-wrapper">
        <span class="material-icons">house</span>
      </a>  
      <span class="example-spacer"></span>

      <div class="toolbar-action__wrapper" *ngIf="loggedInUser">
        <a routerLink="#" class="toolbar-action">
          <mat-icon>account_circle</mat-icon>
          <span class="action-label"> {{loggedInUser.firstName}} {{loggedInUser.lastName}} </span>
        </a>
        <span class="example-spacer"></span>

        <div class="toolbar-action__wrapper" *ngIf="loggedInUser">
          <!-- <a routerLink="#" class="toolbar-action">
            <mat-icon>notifications_none</mat-icon>
          </a> -->
    
          <button mat-icon-button class="toolbar-action" (click)="logout()">
            <mat-icon>exit_to_app</mat-icon>
          </button>
        </div>
      </div>
    </div>
</mat-toolbar>
<mat-drawer-container class="example-container">
    <mat-drawer class="navigation__drawer" mode="side" opened *ngIf="navigationIsVisible == true && loggedInUser && !isHomePageOrLoginPage()">
      <div class="navigation-links__container">

        <mat-tree [dataSource]="nestedDataSource" [treeControl]="nestedTreeControl" class="navigation-links__link-wrapper">

          <mat-tree-node *matTreeNodeDef="let node">
            <a id="{{node.id}}" (click)="onNodeClick($event,node); $event.preventDefault()" [routerLink]="node.url" class="mat-tree-node" [ngClass]="{
                'navigation-links__sub-link': node.isChild,
                'navigation-links__main-link': !node.isChild,
                'navigation-links__main-link--routing-disabled': node.disableRouting,
                'navigation-links__main-link--disabled navigation-links__main-link--routing-disabled': node.disabled,
                'navigation-links__main-link--active': selectedNode === node,
                'selected-node': selectedNodeItem === node}" #clickedItem>
              <mat-icon [ngClass]="{'rotate-icon': node.id === '0' && shouldRotate}" *ngIf="!node.isChild">{{node.icon}}</mat-icon>
              {{ node.label }}
            </a>
          </mat-tree-node>

          <mat-nested-tree-node *matTreeNodeDef="let node; when: nodeHasChildren; let i = index;" class="navigation-links">
            <span matTreeNodeToggle class="navigation-links__main-link-wrapper">
              <a id="{{node.id}}" [routerLink]="node.url"  (click)="onNodeClick($event, node); $event.preventDefault()" class="mat-tree-node navigation-links__main-link"
              [ngClass]="{
                'navigation-links__main-link--routing-disabled': node.disableRouting,
                'navigation-links__main-link--disabled': node.disabled,
                'navigation-links__main-link--active': nestedTreeControl.isExpanded(node),
                'selected-node': selectedNode === node}" #clickedItem>
                <mat-icon *ngIf="!node.isChild">{{node.icon}}</mat-icon>
                {{ node.label }}
              </a>

              <span class="navigation-links__sub-link-wrapper" [class.example-tree-invisible]="!nestedTreeControl.isExpanded(node)">
                <ng-container matTreeNodeOutlet></ng-container>
              </span>
            </span>
          </mat-nested-tree-node>

        </mat-tree>
      </div>
    </mat-drawer>

    <mat-drawer-content class="navigation__content">
      <router-outlet></router-outlet>
    </mat-drawer-content>

</mat-drawer-container>