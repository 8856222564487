import { Injectable } from '@angular/core';
import { Firestore, onSnapshot,setDoc, doc, writeBatch, getDocs, query, collection, getDoc, updateDoc, increment, where } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ReadService {
  private leads: any[] = [];
  private data = new BehaviorSubject<Object>({});
  currentData = this.data.asObservable();
  constructor(private firestore: Firestore) {
    
   }

   getLeadsByDisposition(collectionName: string, disposition: string): Observable<any[]> {
    const collectionRef = collection(this.firestore, collectionName);
    const queryDoc = query(collectionRef, where("disposition", "==", disposition));
    return new Observable((observer) => {
      const unsubscribe = onSnapshot(queryDoc, (querySnapshot) => {
        const leads = [];
        querySnapshot.forEach((doc) => {
          leads.push(doc.data());
        });
        observer.next(leads);
      });
      return { unsubscribe };
    });
  }
  async takeActionOnLead(adcomment: string,id: string , status: string){
    const userDocRef = doc(this.firestore, "leads", id);
    await updateDoc(userDocRef, { disposition: status ,additionalComment: adcomment});
  }
}
