import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwButtonComponent } from './pw-button.component';



@NgModule({
  declarations: [
    PwButtonComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    PwButtonComponent
  ]
})
export class PwButtonModule { }
