import { Component, Input, OnInit } from '@angular/core';
import readXlsxFile from 'read-excel-file'
import { FormGroup, Validators } from '@angular/forms';
import { LeadModel } from './lead-model';
import { BatchModel } from './batch-model';
import * as moment from 'moment';
import { PwFormService } from '../../helpers/form-service.service';
import { UploadDataService } from './upload-data.service';

@Component({
  selector: 'upload-data',
  templateUrl: './upload-data.component.html',
  styleUrls: ['./upload-data.component.scss']
})
export class UploadDataComponent implements OnInit {

  @Input() data: any;
  file: any;
  noOfRecords: number;
  isDisabled: boolean = true;
  uploadNewBatchForm: FormGroup;
  type: string;
  validationMessages: any;
  formStatus: any = {
    formErrors: {},
    submitClicked: false
  };
  newBatch: BatchModel =  new BatchModel({
    name: '',
    // uploadDate: this.getOptinDate(),
    uploadDate: '',
    source: '',
    uploaderId: '',
    leads: []
  });
  dataUploadResponses: any;
  isLoading: boolean = false;
  year: number = new Date().getFullYear()

  constructor(
    private formService: PwFormService,
    private uploadDataService: UploadDataService) { }

  ngOnInit() {
    this.createForm();
    this.uploadDataService.onReset.subscribe((e) => {
      this.uploadNewBatchForm.reset();
      this.noOfRecords = 0;
    });
  }

  createForm() {
    let ctrls = [
      {
        controlName: 'batchName',
        validation: {
          require: {
            validator: Validators.required,
            message: 'Please enter a valid data batch name'
          }
        }
      },
      
      {
        controlName: 'sheet',
        validation: {
          require: {
            validator: Validators.required,
            message: 'Please upload a valid excel sheet'
          }
        }
      },
    ]

    if(this.data.hasDataSource) {
      let dataSourceCtrl = {
        controlName: 'batchSource',
        validation: {
          require: {
            validator: Validators.required,
            message: 'Please enter a valid data batch source name'
          }
        }
      };
      ctrls.push(dataSourceCtrl);
    }

    this.formService.createNewForm(ctrls);

    this.uploadNewBatchForm = this.formService.getFormGroup();
    this.formStatus = this.formService.getFormStatus();

    this.uploadNewBatchForm.get('batchName').valueChanges.subscribe((value) => {
      this.newBatch.name = value;
    });

    if (this.data.hasDataSource) {
      this.uploadNewBatchForm.get('batchSource').valueChanges.subscribe((value) => {
        this.newBatch.source = value;
      });
    }

    this.uploadNewBatchForm.statusChanges.subscribe((status) => {
      if (status === 'VALID') {
        this.isDisabled = false;
      }
    });

    this.uploadDataService.batchNameSet.subscribe((name) => {
      this.uploadNewBatchForm.get('batchName').setValue(name);
    })
  }

  getExcelSheet(e) {
    let files = e.target.files[0];
    this.uploadDataService.onChange(e);

    readXlsxFile(files).then((rows) => {
      this.noOfRecords = rows.length; 
      this.uploadDataService.setBatchSize(this.noOfRecords);
      /* 
        see https://github.com/catamphetamine/read-excel-file/issues/38#issuecomment-544286628
        to upload files larger than 5000
      */

      if (this.noOfRecords > 0) {  
        rows.map((row: any, i) => {
          let dynamicLead = {};

          this.data.indeces.map((index) => {
            dynamicLead[index.key] = row[index.index]
          })

          let newLead = dynamicLead

          this.newBatch.leads.push(newLead);
        });

        this.uploadDataService.setBatch(this.newBatch);
      }
    });
  }

  uploadNewBatch() {
    this.uploadDataService.uploadClicked(this.uploadDataService.getBatch());
  }

  addBatchLead() {
    // https://www.sitepoint.com/javascript-large-data-processing/
    // code avilable on https://gitlab.com/kalevamediaprojects/evolution-world/-/blob/main/src/app/cartrack/cartrack.component.ts#L87
  }

  getOptinDate() {
    let currentDate = new Date(), optinDate = moment(currentDate).format('DD/MM/YYYY HH:mm:ss');
    return optinDate;
  }

}
