import { EventEmitter, Injectable, Output } from '@angular/core';
import { BatchModel } from './batch-model';
import { LeadModel } from './lead-model';

@Injectable({
  providedIn: 'root'
})
export class UploadDataService {
  batch: BatchModel;
  isLoading: boolean = false;
  batchSize: number;
  @Output() onUploadClicked: EventEmitter<any> = new EventEmitter();
  @Output() onReset: EventEmitter<any> = new EventEmitter();
  @Output() onChanged: EventEmitter<any> = new EventEmitter();
  @Output() batchNameSet: EventEmitter<any> = new EventEmitter();

  constructor() { }

  setBatch(batch: BatchModel) {
    this.batch = batch;
  }

  getBatch(): BatchModel {
    return this.batch;
  }

  getBatchLeads(): LeadModel[] {
    return this.batch.leads;
  }

  uploadClicked(data) {
    this.onUploadClicked.emit(data);
  }

  setLoadingStatus(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  getLoadingStatus() {
    return this.isLoading;
  }

  onChange(event: any) {
    this.onChanged.emit(event);
  }

  setBatchName(name: string) {
    this.batchNameSet.emit(name);
  }

  setBatchSize(size: number) {
    this.batchSize = size;
  }

  getBatchSize() {
    return this.batchSize;
  }

  reset() {
    this.onReset.emit();
  }

}
