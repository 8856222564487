import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuItemModel } from './MenuItemModel';
import { UserService } from './Services/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PwThemeingService } from '@kaleva-media/purple-water-next';
import { environment } from 'src/environments/environment';
import { CampaignServiceService } from './Services/campaign-service.service';
import { WriteService } from './Services/write.service';
import { CampaignTemplateService } from '@kaleva-media/purple-water-next';
import { AlertService } from './Services/alert.service';
import { AuthService } from './Services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loggedInUser: any;
  constructor() {}
  ngOnInit() {
    const storedUser = sessionStorage.getItem("usr");
    if (storedUser) {
      try {
        this.loggedInUser = JSON.parse(storedUser).value;
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }
  ngAfterViewInit() {}
}
