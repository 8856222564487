import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Firestore, setDoc, doc, writeBatch, getDocs, query, collection, getDoc, updateDoc, increment, where } from '@angular/fire/firestore';
import { PwDropdownService, PwFirebaseService, PwThemeingService, PwThemeModel } from '@kaleva-media/purple-water-next';
import moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class WriteService {
  loggedInUser: any;
  constructor(private firebase: PwFirebaseService,private firestore: Firestore) {
  }
  addLead(leadinfo, campaignName){
    //const now =  moment(new Date()).format('DD/MM/YYYY HH:MM:SS');
    const storedUser = sessionStorage.getItem("usr");
    if (storedUser) {
      try {
        this.loggedInUser = JSON.parse(storedUser).value;
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
    leadinfo['date'] = Date.now();
    leadinfo['id'] = leadinfo.tel;
    leadinfo['disposition'] = "new";
    leadinfo['campaignName'] = campaignName;
    leadinfo['sender'] =  this.loggedInUser;
    delete leadinfo['url'];
    this.saveRecord(leadinfo, 'leads').then(() => {
      this.updateLeadCounter("leads").then(()=>{
        this.updateCampaignCounter(campaignName);
      });
    }).catch((data)=>{
      console.log(data);
    });
  }
  /**
   * 
   * @param data formObject
   * @param collectionName 
   * @returns update a document if different campaigns/ set a document if does not exists
   */
  async saveRecord(data: any, collectionName: string) {
    const docRef = doc(this.firestore, collectionName, data.id);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      const docdata = docSnapshot.data();
      if(docdata.campaignName == data['campaignName'])
      { 
        //same campaign throw an error
        return new Promise((resolve, reject) => {
          reject(`document ${docdata.id} with ${docdata.campaignName} campaign already exists!`);
        });
      }
      else{
        //different campaign update data
        return updateDoc(docRef, data);
      }
    }
    else{
      return setDoc(doc(this.firestore, collectionName, data.id), data);
    }
  }
  async updateLeadCounter(collectionName){
    let docRef, incrementData;
    docRef = doc(this.firestore, 'counters', collectionName);
    incrementData = { total: increment(1) };
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      return updateDoc(docRef, incrementData);
    }else {
      return setDoc(docRef, incrementData);
    }
  }
  async updateCampaignCounter(campaignName){
    const docRef = doc(this.firestore, 'counters', 'leads');
    const subcollectionRef = collection(docRef, 'campaigns');
    const countDocRef = doc(subcollectionRef, campaignName);
    const docSnapshot = await getDoc(countDocRef);
    if (docSnapshot.exists()) {
      return updateDoc(countDocRef, { total: increment(1),campaignName: campaignName });
    } else {
      return setDoc(countDocRef, { total: 1,campaignName: campaignName  });
    }
  }
  async getallLeads(){
    const collectionRef = collection(this.firestore, 'leads');
    const querySnapshot = await getDocs(collectionRef);
    return querySnapshot;
    // querySnapshot.forEach((doc) => {
    //      console.log(doc.id, ' => ', doc.data());
    // });
  }
  async getleadById(id: string){
    const docRef = doc(this.firestore, 'leads', id);
    const docSnapshot = await getDoc(docRef);
    if (docSnapshot.exists()) {
      const docdata = docSnapshot.data();
      return docdata;
    }
    return 'document does not exists!'
  }
}
