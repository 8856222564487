<div *ngFor="let item of leads">
  <div class="client-info">
    <div class="row">
      <div class="d-flex justify-content-between align-items-center"> 
        <h6 class="heading">Client Details</h6>
        <button class="toggle" (click)="toggleClicked()">
          <svg *ngIf="isExpanded" width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.59 0.590027L6 5.17003L1.41 0.590027L0 2.00003L6 8.00003L12 2.00003L10.59 0.590027Z" fill="white" fill-opacity="0.56"/>
          </svg> 
          <svg *ngIf="!isExpanded"  width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.41 7.40991L6 2.82991L10.59 7.40991L12 5.99991L6 -8.7738e-05L0 5.99991L1.41 7.40991Z" fill="white" fill-opacity="0.56"/>
          </svg>                       
        </button>
      </div>
        <mat-form-field appearance="outline" class="input-field">
          <mat-label>First Name</mat-label>
          <input matInput type="text" [disabled]="true" value="{{item.name}}">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Last Name</mat-label>
          <input matInput type="text" [disabled]="true" value="{{item.surname}}">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Email</mat-label>
          <input matInput type="text" [disabled]="true" value="{{item.email}}">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Contact</mat-label>
          <input matInput type="text" [disabled]="true" value="{{item.tel}}">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Company Name</mat-label>
          <input matInput type="text" [disabled]="true" value="{{item.sender.companyName}}">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Disposition</mat-label>
          <input matInput type="text" [disabled]="true" value="{{item.disposition}}">
        </mat-form-field>
    </div>
    <div class="row">
      <div class="col-8">
        <mat-form-field appearance="outline" class="info">
          <mat-label>Comments</mat-label>
          <input matInput type="text" [disabled]="true" value="{{item.comments}}">
        </mat-form-field>
      </div>
    </div>
  <div *ngIf="!isExpanded">
    <div class="wrapper"> 
      <div class="row">
        <h6 class="heading">Campaign Details</h6>
        <div class="col-4">
          <div class="info">
            <label>Campaign Name:</label>
            <span>{{item.campaignName}}</span>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4" *ngFor="let entry of objectEntries(item)">
          <div class="info" *ngIf="entry[0] !== 'sender'">
            <label>{{ entry[0] }}:</label>
            <span>{{ entry[1] }}</span>
          </div>
        </div>
      </div>
    </div>
    <form [formGroup]="myForm" >
      <mat-form-field>
        <mat-label> Additional Comments (Optional): </mat-label>
        <textarea  matInput formControlName="inputText" placeholder="Enter text"  ></textarea>
      </mat-form-field>
    <div>
        <button mat-raised-button color="primary" (click)="approve(item)" class="custom-button mr-2">Approve</button>
        <button mat-raised-button color="warn" (click)="decline(item)" class="custom-button ml-2">Decline</button>
        <button mat-raised-button color="warn" (click)="review(item)" class="custom-button ml-2">Review</button>
    </div>
  </form>
  </div>  
  </div>
</div>
