import { Injectable } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PayrollService {

  constructor() { }

  /**
   * Get agents leads between start date and end date
   * @param agentName agent leads in question
   * @param startDate 
   * @param endDate 
   * @param leads 
   * @returns 
   */
  getAgentLeadsByDateRange(agentName: string, startDate: Date, endDate: Date, leads: any) : any[] {
    let leadsArr = this.getAllAgentLeads(agentName, leads);
    let leadsInDateRange = [];

    leadsArr.map((lead) => {
      let leadDate = new Date(lead.leadDate);
      if (leadDate >= startDate && leadDate <= endDate) {
        leadsInDateRange.push(lead);
      }
    });
    return leadsInDateRange;
  }

  /**
   * Get all the agents lead in the database
   * @param agentName 
   * @param leads 
   * @returns agentLeads array
   */
  getAllAgentLeads(agentName: string, leads: any) : any[] {
    let agentLeads: any[] = [];
    let leadIds = Object.keys(leads);
    leadIds.map((key) => {
      if (leads[key].agentName === agentName) {
        agentLeads.push(leads[key]);
      }
    });
    return agentLeads;
  }

  /**
   * Get agent leads for a specified campaign
   * @param agentName 
   * @param leads 
   * @returns 
   */
  getAgentLeadsPerCampaign(agentName: string, leads: any) : any {
    let totalNumberOfLeads = 0;
    let leadsArr = this.getAllAgentLeads(agentName, leads);

    let leadCampaigns = this.getUniqueItems(leadsArr, 'campaign');

    Object.keys(leadCampaigns).map((key) => {
      totalNumberOfLeads += leadCampaigns[key];
    })

    let agentLeadsCampaign = { 
      agent: agentName,
      campaigns: leadCampaigns,
      totalNumberOfCampaigns: totalNumberOfLeads
    };

    return agentLeadsCampaign;
  }
  
  
  getAgentLeadsPerCampaignByArray(agentName: string, leadsArr: any[], uniqueIdentifier?: string) : any {
    uniqueIdentifier = uniqueIdentifier ? uniqueIdentifier : 'campaign';
    let totalNumberOfLeads = 0;
    let leadCampaigns = this.getUniqueItems(leadsArr, uniqueIdentifier);

    Object.keys(leadCampaigns).map((key) => {
      totalNumberOfLeads += leadCampaigns[key];
    })

    let agentLeadsCampaign = { 
      agent: agentName,
      campaigns: leadCampaigns,
      totalNumberOfCampaigns: totalNumberOfLeads
    };

    return agentLeadsCampaign;
  }


  /**
   * Gets the number of unique items in an array 
   * @param inputArr 
   * @param uniqueIdentifier 
   * @returns object
   */
  getUniqueItems(inputArr: any[], uniqueIdentifier: string): any {
    var arr = inputArr, obj = {};
    for (var i = 0; i < arr.length; i++) {
      if (!obj[arr[i][uniqueIdentifier]]) {
        obj[arr[i][uniqueIdentifier]] = 1;
      } else if (obj[arr[i][uniqueIdentifier]]) {
        obj[arr[i][uniqueIdentifier]] += 1;
      }
    }
    return obj;
  }

  calculatePricePerLead(agentLeadObj: any, campaigns: any[]): any {
    let agentCampaigns: any[] = [];
    let payrollItems: any[] = [];
    let totalEarned: number = 0;
    let payrollItemsObj: any = {};
    Object.keys(agentLeadObj.campaigns).map((agentCampaignName: string) => {
      console.log(agentCampaignName)
      campaigns.map(campaign => {
        if (agentCampaignName === campaign.name) {
          campaign['totalSubmitted'] = agentLeadObj.campaigns[agentCampaignName];
          agentCampaigns.push(campaign);
        }
      })
    });

    agentCampaigns = _.uniqBy(agentCampaigns, 'name');

    agentCampaigns.map((agentCampaign) => {
      let item = {
        campaignName: agentCampaign.name,
        campaignTitle: agentCampaign.title,
        quantity: agentCampaign.totalSubmitted,
        pricePerLead: agentCampaign.commercials.agentPaymentPrice,
        subTotalEarned: agentCampaign.totalSubmitted * agentCampaign.commercials.agentPaymentPrice
      }
      payrollItems.push(item);
    });

    payrollItemsObj['items'] = payrollItems;

    payrollItems.map((payrollItem) => {
      totalEarned += parseFloat(payrollItem.subTotalEarned);
    });

    payrollItemsObj['totalEarned'] = totalEarned;

    return payrollItemsObj;
  }
}
