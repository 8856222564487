import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }

  /**
   * Format date to YYYY/MM/DD
   * @param date date to format
   * @param divider char t odivide date values
   * @returns 
   */
  public formatDate(date: Date, divider?: string) : any {
    divider = divider ? divider : '/';
    return date.getFullYear() + divider + date.getMonth() + divider + date.getDay();
  }
}
