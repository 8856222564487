import { LeadModel } from './lead-model';

export class BatchModel {
    public name: string;
    public source: string;
    public uploadDate: string;
    public uploaderId: string;
    public leads: any[];

    constructor(args: BatchModel) {
        for(const key in args){
            this[key] = args[key];
        }
    }
}