import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwInputModule } from '../../form-modules/pw-input/pw-input.module';
import { PwButtonModule } from '../../form-modules/pw-button/pw-button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UploadDataComponent } from './upload-data.component';
import { UploadDataService } from './upload-data.service';


@NgModule({
  declarations: [
    UploadDataComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    PwInputModule,
    PwButtonModule
  ],
  providers: [
    UploadDataService
  ],
  exports: [
    UploadDataComponent
  ]
})
export class UploadDataModule { }
