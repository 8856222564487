<div id="login-form-wrap">
    <h2>Login</h2>
    <form id="login-form">
      <p>
      <input type="email" id="email" name="email" placeholder="Email Address" required [(ngModel)]="email"><i class="validation"><span></span><span></span></i>
      </p>
      <p>
        <input type="password" id="username" name="username" placeholder="Password" required [(ngModel)]="password"><i class="validation"><span></span><span></span></i>
      </p>
      <p>
      <input type="submit" id="login" value="Login" (click)="LoginUser()">
      </p>
    </form>
    <div id="create-account-wrap">
      <p>Not a member? <a (click)="navigateToCreateAccount()" >Create Account</a><p>
    </div>
</div>