import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwDropdownComponent } from './pw-dropdown.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';



@NgModule({
  declarations: [
    PwDropdownComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule
  ],
  exports: [
    PwDropdownComponent
  ]
})
export class PwDropdownModule { }
