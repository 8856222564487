<div class="pw-dropdown" [formGroup]="parentFormGroup">
    <label class="pw-dropdown__label" *ngIf="data.label">
      {{ data.label }}
    </label>
    
    <div class="pw-dropdown__wrapper" [ngClass]="{'pw-dropdown__wrapper--expanded': isExpanded}" (click)="isExpanded = !isExpanded">
      <span class="pw-dropdown__value">
        {{ placeholder }}
      </span>
      <span class="pw-dropdown__icon-wrapper">
        <svg style="width:24px;height:24px" viewBox="0 0 24 24">
          <path fill="currentColor" d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z" />
        </svg>
      </span>
    </div>
    
    <div class="pw-dropdown__options-wrapper">
      <div class="pw-dropdown__option" *ngFor="let option of data.options; let i = index" (click)="selectOption(option)">
        {{ option.label }}
      </div>
    </div>
</div>