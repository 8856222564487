<div class="container">
    <div class="title">Registration</div>
    <div class="content">
    <form [formGroup]="createUser" (ngSubmit)="registerUser()">
        <div class="user-details">
        <div class="input-box">
            <span class="details">First Name</span>
            <input type="text" placeholder="Enter your first name" required formControlName="firstName">
        </div>
        <div class="input-box form-group">
            <span class="details">Last Name</span>
            <input type="text" placeholder="Enter your last name" required  formControlName="lastName">
        </div>
        <div class="input-box form-group">
            <span class="details">Company Name</span>
            <input type="text" placeholder="Enter company name" required formControlName="companyName">
        </div>
        <div class="input-box form-group">
            <span class="details">Company Registration Number</span>
            <input type="text" placeholder="Enter company registration number" required formControlName="companyRegistrationNo">
        </div>
        <div class="input-box form-group">
            <span class="details">Phone Number</span>
            <input type="number" placeholder="Enter your number" required formControlName="mobileNumber">
        </div>
        <div class="input-box form-group">
            <span class="details">Email</span>
            <input type="email" placeholder="Enter your email" required formControlName="email">  
        </div>
        <div class="input-box form-group">
            <span class="details">Password</span>
            <input type="password" placeholder="Enter your password" required formControlName="password">
        </div>
        <div class="input-box form-group">
            <span class="details">Confirm Password</span>
            <input type="password" placeholder="Confirm your password" required formControlName="Repassword">
        </div>
        </div>
        <div class="button">
          <input type="submit" id="login" value="Register">
        </div>
    </form>
      <div id="create-account-wrap">
        <p>Already Have an Account? <a (click)="navigateToLogin()">Login</a><p>
      </div>
    </div>
</div>