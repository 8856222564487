import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'pw-input',
  templateUrl: './pw-input.component.html',
  styleUrls: ['./pw-input.component.scss']
})
export class PwInputComponent implements OnInit {

  @Input() parentFormGroup: FormGroup;
  @Input() data: any;
  @Input() formStatus: any;
  
  constructor() { }

  ngOnInit() {
  }

}
