import { Injectable, Output, EventEmitter, Directive } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as $ from 'jquery';
import { environment } from 'src/environments/environment';

@Directive()
@Injectable()
export class GoogleApiIntegrationService {

  @Output() names: EventEmitter<any> = new EventEmitter();
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json',
    })
  };

  kinpPriceWritterApi: string = environment.kingPriceWriterApi;
  atarocolReadNamesApi: string = environment.readerApi;
  lifeWriterApi: string = environment.lifeWriterApi;
  autoAndGeneralWriterApi: string = environment.autoAndGeneralWriterApi;
  budgetInsuranceWriterApi: string = environment.budgetInsuranceWriterApi;
  firstForWomenWriterApi: string = environment.firstForWomenWriterApi;
  trackerWriterApi: string = environment.tracker.trackerWriterApi;
  dialDirectWriterApi: string = environment.dialDirectWriterApi;

  constructor(private http: HttpClient) { }

  writeToSheet(data: any) {
    $.post('http://insurecover.co.za/luhambo-leads-management/quickstart.php', {
      firstname: data.firstname,
      surname: data.surname,
      phone: data.phone,
      product: data.product,
      agent_name: data.agentName,
      status: data.status
    }, (response) => {
      console.log(response);
    });
  }

  readNames(): any {
    $.post('http://insurecover.co.za/luhambo-leads-management/readnames.php', {}, (response) => {
      this.names.emit(response);
    });
  }

  reatAtNames(): any {
    $.post(this.atarocolReadNamesApi, {}, (response) => {
      this.names.emit(response);
    });
  }

  readFVNames(): any {
    $.post('http://insurecover.co.za/luhambo-leads-management/fv-agent-names.php', {}, (response) => {
      this.names.emit(response);
    });
  }

  readAssupolNames(): any {
    $.post('http://insurecover.co.za/luhambo-leads-management/assupol-readnames.php', {}, (response) => {
      this.names.emit(response);
    });
  }

  writeToBudgetSheet(data:any) {
    $.post('http://insurecover.co.za/luhambo-leads-management/bugetSheetWriter.php', {
      firstname: data.firstName,
      surname: data.surname,
      phone: data.phone,
      product: data.product,
      agent_name: data.agentName,
      status: data.status,
      reason: data.reason
    }, (response, status) => {
      console.log(response);
    });
  }

  writeToFVSheet(data:any) {
    $.post('http://insurecover.co.za/luhambo-leads-management/fvSheetWriter.php', {
      firstname: data.firstName,
      surname: data.surname,
      phone: data.phone,
      product: data.product,
      agent_name: data.agentName,
      status: data.status,
      reason: data.reason
    }, (response, status) => {
      console.log(response);
    });
  }

  writeToFVSheetLoans(data:any) {
    $.post('http://insurecover.co.za/luhambo-leads-management/fvSheetWriterLoans.php', {
      firstname: data.firstName,
      surname: data.surname,
      phone: data.phone,
      product: data.product,
      agent_name: data.agentName,
      status: data.status,
      reason: data.reason,
      dob: data.dob,
      id: data.id,
      email: data.email,
      optInDate: data.optinDate
    }, (response, status) => {
      console.log(response);
    });
  }

  writeToFVSheetFuneral(data:any) {
    $.post('http://insurecover.co.za/luhambo-leads-management/fvSheetWriterFuneral.php', {
      firstname: data.firstName,
      surname: data.surname,
      phone: data.phone,
      product: data.product,
      agent_name: data.agentName,
      status: data.status,
      reason: data.reason,
      dob: data.dob,
      id: data.id,
      email: data.email,
      optInDate: data.optinDate
    }, (response, status) => {
      console.log(response);
    });
  }

  writeAtHollard(data:any) {
    $.post('http://insurecover.co.za/luhambo-leads-management/atHollardWritter.php', {
      firstname: data.firstName,
      surname: data.surname,
      phone: data.phone,
      agent_name: data.agentName,
      status: data.status,
      email: data.email,
    }, (response, status) => {
      console.log(response);
    });
  }

  writeAtClientele(data:any) {
    $.post('http://insurecover.co.za/luhambo-leads-management/atClienteleWritter.php', {
      firstname: data.firstName,
      surname: data.surname,
      phone: data.phone,
      product: data.product,
      agent_name: data.agentName,
      status: data.status,
      email: data.email,
    }, (response, status) => {
      console.log(response);
    });
  }

  writeToAtLifeSheet(data: any) {
    $.post(this.lifeWriterApi, {
      firstname: data.firstname,
      surname: data.surname,
      phone: data.phone,
      product: data.product,
      agent_name: data.agentName,
      status: data.status
    }, (response) => {
      console.log(response);
    });
  }

  writeToAutoAndGeneralSheet(data: any) {
    $.post(this.autoAndGeneralWriterApi, {
      firstname: data.firstname,
      surname: data.surname,
      phone: data.phone,
      product: data.product,
      agent_name: data.agentName,
      status: data.status,
      comments: data.comments
    }, (response) => {
      console.log(response);
    });
  }

  writeToDialDirectSheet(data: any) {
    $.post(this.dialDirectWriterApi, {
      firstname: data.firstname,
      surname: data.surname,
      phone: data.phone,
      product: data.product,
      agent_name: data.agentName,
      status: data.status,
      comments: data.comments
    }, (response) => {
      console.log(response);
    });
  }

  writeToBudgetInsuranceSheet(data: any) {
    $.post(this.budgetInsuranceWriterApi, {
      firstname: data.firstname,
      surname: data.surname,
      phone: data.phone,
      product: data.product,
      agent_name: data.agentName,
      status: data.status,
      comments: data.comments
    }, (response) => {
      console.log(response);
    });
  }

  writeToFirstForWomenSheet(data: any) {
    $.post(this.firstForWomenWriterApi, {
      firstname: data.firstname,
      surname: data.surname,
      phone: data.phone,
      product: data.product,
      agent_name: data.agentName,
      status: data.status,
      comments: data.comments
    }, (response) => {
      console.log(response);
    });
  }

  writeToTrackerSheet(data: any) {
    $.post(this.trackerWriterApi, {
      firstname: data.firstname,
      surname: data.surname,
      phone: data.phone,
      agent_name: data.agentName,
      initial: data.initial,
      title: data.title,
      idNumber: data.idNumber,
      deviceType: data.deviceType,
      referenceNo: data.referenceNo,
      partnerUniqueRefNo: data.partnerUniqueRefNo,
      status: data.status,
    }, (response) => {
      console.log(response);
    });
  }

  writeToAssupolSheetFuneral(data:any) {
    $.post('http://insurecover.co.za/leads-application/assupolSheetWriter.php', {
      firstname: data.firstName,
      surname: data.surname,
      phone: data.phone,
      product: data.product,
      agent_name: data.agentName,
      status: data.status,
      reason: data.reason,
      optInDate: data.optinDate
    }, (response, status) => {
      console.log(response);
    });
  }

  writeAtKingPrice(data:any) {
    $.post(this.kinpPriceWritterApi, {
      firstname: data.firstName,
      phone: data.phone,
      agent_name: data.agentName,
      status: data.status,
    }, (response, status) => {
      console.log(response);
    });
  }

  readAtarocolAgentNames() {
    $.post(this.atarocolReadNamesApi, {}, (response) => {
      this.names.emit(response);
    });
  }
}
