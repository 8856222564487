import { Component, Input, OnInit } from '@angular/core';
import { HelperService } from '../../helpers/helper.service';
@Component({
  selector: 'pw-date-picker',
  templateUrl: './pw-date-picker.component.html',
  styleUrls: ['./pw-date-picker.component.scss']
})
export class PwDatePickerComponent implements OnInit {

  @Input() data: any;
  @Input() parentFormGroup: any;

  constructor(private helperService: HelperService) { }

  ngOnInit(): void {
  }

}
