import { Component, OnInit } from '@angular/core';
import { Unsubscribe } from '@angular/fire/firestore';
import { FormGroup, FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ReadService } from 'src/app/Services/read.service';
import { AlertService } from './../../Services/alert.service';
import { AuthService } from 'src/app/Services/auth.service';
@Component({
  selector: 'dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  boxColor: string = 'white';
  expanded: boolean = false;
  myForm: FormGroup;
  currentData: any;
  private subscription: Subscription;
  public leads: any[] = [];
  inputValue: any;
  isExpanded: boolean = true;
  filteredLeads: any;
  constructor(private read: ReadService,private _alertService: AlertService,private auth: AuthService) { }
  formStatus: any = {
    formErrors: {},
    submitClicked: false
  }
  ngOnInit() {
    console.log(JSON.parse(sessionStorage["usr"]).value.companyName);
    this.subscription = this.read.getLeadsByDisposition('leads','new').subscribe((leads) => {
      leads.sort((a, b) => {
        const currentTime = Date.now();
        const timeA = Math.abs( a.date - currentTime);
        const timeB = Math.abs( b.date - currentTime);
        return timeA - timeB;
      });
      if(!JSON.parse(sessionStorage["usr"]).value.admin){
        this.filteredLeads = leads.filter((lead) => lead.sender.companyName === JSON.parse(sessionStorage["usr"]).value.companyName);
        this.leads = this.filteredLeads;
      }
      else{
        this.leads = leads;
      }
      
    });

    this.myForm = new FormGroup({
      inputText: new FormControl(''),
      name: new FormControl('')
    });
  }
  objectEntries(obj: any): any[] {
    return Object.entries(obj);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  approve(lead: any) {
    this.inputValue = this.myForm.get('inputText').value;
    this.read.takeActionOnLead(this.inputValue,lead.id,'approved');
    this._alertService.notificationToast('success',`lead for ${lead.name +' '+lead.surname}  successfully approved!`);
  }

  decline(lead: any) {
    this.inputValue = this.myForm.get('inputText').value;
    this.read.takeActionOnLead(this.inputValue,lead.id,'declined');
    this._alertService.notificationToast('warning',`lead for ${lead.name +' '+lead.surname}  has been declined!`);
  }

  review(lead: any) {
    this.inputValue = this.myForm.get('inputText').value;
    this.read.takeActionOnLead(this.inputValue,lead.id,'review');
    this._alertService.notificationToast('success',`lead for ${lead.name +' '+lead.surnames}  has been added to review!`);
  }

  toggleClicked(){
    this.isExpanded = !this.isExpanded;
  }
}
