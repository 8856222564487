// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  crmApiUrl: 'https://luhambomarketing.co.za/Luhambo-CRM-dev/apis/db-api.php',
  tracker: {
    trackerUsername: 'Gilela',
    trackerPassword: 'Tracker@112#',
    sourceId: '55387',
    trackerSubmitterApi: 'http://gilela.kalevamedia.com/apis/tracker-submitter.php',
    trackerWriterApi: 'http://gilela.kalevamedia.com/apis/tracker-writer.php'
  },
  readerApi: 'http://gilela.kalevamedia.com/apis/at-readnames.php',
  kingPriceWriterApi: 'http://gilela.kalevamedia.com/writers/kingPriceSheetWriter.php',
  kingPriceSubmitterApi: 'http://gilela.kalevamedia.com/submitters/submit-kingprice-lead.php',
  readNamesApi: 'http://gilela.kalevamedia.com/readers/at-readnames.php',
  lifeWriterApi: 'http://gilela.kalevamedia.com/writers/atLifeWriter.php',
  autoAndGeneralWriterApi: 'http://gilela.kalevamedia.com/writers/autoAndGeneralWriter.php',
  budgetInsuranceWriterApi: 'http://gilela.kalevamedia.com/writers/budgetInsuranceWriter.php',
  firstForWomenWriterApi: 'http://gilela.kalevamedia.com/writers/firstForWomenWriter.php',
  dialDirectWriterApi: 'http://gilela.kalevamedia.com/writers/dialDirectWriter.php',
  firebaseConfig: {
    apiKey: "AIzaSyD068LxDaKb4R2hjGRoMi6JLsAlzs6d69E",
    authDomain: "hotleadsdb.firebaseapp.com",
    projectId: "hotleadsdb",
    storageBucket: "hotleadsdb.appspot.com",
    messagingSenderId: "333556908134",
    appId: "1:333556908134:web:26d09a03412f9919296da7",
    measurementId: "G-Y2W3GCV25K"
  },

  campaigns: [
    {
      name: 'prestigeMedical',
      title: 'Prestige Medical',
      type: 'custom', //affiliate || telesure || custom
      path: 'prestige-medical',
      imgSrc: 'prestige.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '#', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'strawberryWorx',
      title: 'Strawberry Worx',
      type: 'custom', //affiliate || telesure || custom
      path: 'strawberry-worx',
      imgSrc: 'strawberrybillboards.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'solidStreams',
      title: 'Solid Streams',
      type: 'custom', //affiliate || telesure || custom
      path: 'solid-streams',
      imgSrc: 'Solid_logo.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'cartrack',
      title: 'Cartrack',
      type: 'custom', //affiliate || telesure || custom
      path: 'cartrack',
      imgSrc: 'cartrack.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'kingPrice',
      title: 'King Price',
      type: 'custom', //affiliate || telesure || custom
      path: 'kingprice',
      imgSrc: 'king-price.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'mixTelematics',
      title: 'Mix Telematics',
      type: 'custom', //affiliate || telesure || custom
      path: 'mix-telematics',
      imgSrc: 'mix_telematics.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'address',
          label: 'Address Info:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'id',
          label: 'ID/Passport:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter your ID/Passport'
            }
          ]
        },
        {
          name: 'address',
          label: 'Address:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter your address'
            }
          ]
        },
        {
          name: 'emergencyContact1',
          label: 'Emergency Contact 1 Name and Number:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter emergency contact 1 name and number'
            }
          ]
        },
        {
          name: 'emergencyContact2',
          label: 'Emergency Contact 2 Name and Number:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter emergency contact 2 name and number'
            }
          ]
        },
        {
          name: 'emergencyContact3',
          label: 'Emergency Contact 3 Name and Number:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter emergency contact 3 name and number'
            }
          ]
        },
        {
          name: 'numberOfVehicles',
          label: 'Number of Vehicles:',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the number of vehicles'
            }
          ]
        },
        {
          name: 'vehicleMake',
          label: 'Vehicle Make:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the vehicle make'
            }
          ]
        },
        {
          name: 'vehicleModel',
          label: 'Vehicle Model:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the vehicle model'
            }
          ]
        },
        {
          name: 'yearModel',
          label: 'Year Model:',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the year model'
            }
          ]
        },
        {
          name: 'vehicleColour',
          label: 'Vehicle Colour:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the vehicle colour'
            }
          ]
        },
        {
          name: 'vehicleRegistration',
          label: 'Vehicle Registration:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the vehicle registration'
            }
          ]
        },
        {
          name: 'bank',
          label: 'Bank:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the bank'
            }
          ]
        },
        {
          name: 'accountNumber',
          label: 'Account Number:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the account number'
            }
          ]
        },
        {
          name: 'accountType',
          label: 'Type of Account:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the type of account'
            }
          ]
        },
        {
          name: 'accountHolderName',
          label: 'Name of Account Holder:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the name of the account holder'
            }
          ]
        },
        {
          name: 'debitOrderDate',
          label: 'Debit Order Date:',
          type: 'input',
          inputType: 'date',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the debit order date'
            }
          ]
        },
        {
          name: 'product',
          label: 'Product:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the product'
            }
          ]
        },
        {
          name: 'fitmentCentre',
          label: 'Fitment Centre:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the fitment centre'
            }
          ]
        },
        {
          name: 'fitmentDate',
          label: 'Fitment Date:',
          type: 'input',
          inputType: 'date',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the fitment date'
            }
          ]
        },
        {
          name: 'fitmentAddress',
          label: 'Fitment Address:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the fitment address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'NetStar',
      title: 'NetStar',
      type: 'custom', //affiliate || telesure || custom
      path: 'netstar',
      imgSrc: 'netstar.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'address',
          label: 'Address Info:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'id',
          label: 'ID/Passport:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter your ID/Passport'
            }
          ]
        },
        {
          name: 'address',
          label: 'Address:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter your address'
            }
          ]
        },
        {
          name: 'emergencyContact1',
          label: 'Emergency Contact 1 Name and Number:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter emergency contact 1 name and number'
            }
          ]
        },
        {
          name: 'emergencyContact2',
          label: 'Emergency Contact 2 Name and Number:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter emergency contact 2 name and number'
            }
          ]
        },
        {
          name: 'emergencyContact3',
          label: 'Emergency Contact 3 Name and Number:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter emergency contact 3 name and number'
            }
          ]
        },
        {
          name: 'numberOfVehicles',
          label: 'Number of Vehicles:',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the number of vehicles'
            }
          ]
        },
        {
          name: 'vehicleMake',
          label: 'Vehicle Make:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the vehicle make'
            }
          ]
        },
        {
          name: 'vehicleModel',
          label: 'Vehicle Model:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the vehicle model'
            }
          ]
        },
        {
          name: 'yearModel',
          label: 'Year Model:',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the year model'
            }
          ]
        },
        {
          name: 'vehicleColour',
          label: 'Vehicle Colour:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the vehicle colour'
            }
          ]
        },
        {
          name: 'vehicleRegistration',
          label: 'Vehicle Registration:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the vehicle registration'
            }
          ]
        },
        {
          name: 'bank',
          label: 'Bank:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the bank'
            }
          ]
        },
        {
          name: 'accountNumber',
          label: 'Account Number:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the account number'
            }
          ]
        },
        {
          name: 'accountType',
          label: 'Type of Account:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the type of account'
            }
          ]
        },
        {
          name: 'accountHolderName',
          label: 'Name of Account Holder:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the name of the account holder'
            }
          ]
        },
        {
          name: 'debitOrderDate',
          label: 'Debit Order Date:',
          type: 'input',
          inputType: 'date',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the debit order date'
            }
          ]
        },
        {
          name: 'product',
          label: 'Product:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the product'
            }
          ]
        },
        {
          name: 'fitmentCentre',
          label: 'Fitment Centre:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the fitment centre'
            }
          ]
        },
        {
          name: 'fitmentDate',
          label: 'Fitment Date:',
          type: 'input',
          inputType: 'date',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the fitment date'
            }
          ]
        },
        {
          name: 'fitmentAddress',
          label: 'Fitment Address:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter the fitment address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'wonga',
      title: 'wonga',
      type: 'custom', //affiliate || telesure || custom
      path: 'wonga',
      imgSrc: 'wonga.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'assupol',
      title: 'Assupol',
      type: 'custom', //affiliate || telesure || custom
      path: 'assupol',
      imgSrc: 'assupol.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'autoAndGeneral',
      title: 'Auto and General',
      type: 'custom', //affiliate || telesure || custom
      path: 'auto-and-general',
      imgSrc: 'auto-and-general.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'budget',
      title: 'Budget Insurance',
      type: 'custom', //affiliate || telesure || custom
      path: 'budget-insurance',
      imgSrc: 'budget-insurance-logo.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'taxiSure',
      title: 'Taxi Sure',
      type: 'custom', //affiliate || telesure || custom
      path: 'taxi-sure',
      imgSrc: 'rightsure.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'dotsure',
      title: 'Dotsure',
      type: 'custom', //affiliate || telesure || custom
      path: 'dotsure',
      imgSrc: 'dotsure.svg',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'dialDirect',
      title: 'Dial Direct',
      type: 'custom', //affiliate || telesure || custom
      path: 'dial-direct',
      imgSrc: 'dial-direct.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'firstForWomen',
      title: 'First for Women',
      type: 'custom', //affiliate || telesure || custom
      path: 'firstForWomen',
      imgSrc: 'first-for-women.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'potholeProtector',
      title: 'Pothole Protector',
      type: 'custom', //affiliate || telesure || custom
      path: 'pothole-protector',
      imgSrc: 'dotmotor.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'clienteleLife',
      title: 'Clientele Life',
      type: 'custom', //affiliate || telesure || custom
      path: 'clientele',
      imgSrc: 'clientele.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'vodalend',
      title: 'Voda Lend',
      type: 'custom', //affiliate || telesure || custom
      path: 'vodalend',
      imgSrc: 'vodalend.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'debtMate',
      title: 'Debt Mate',
      type: 'custom', //affiliate || telesure || custom
      path: 'debt-mate',
      imgSrc: 'debtmate.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
    {
      name: 'debtRescue',
      title: 'Debt Rescue',
      type: 'custom', //affiliate || telesure || custom
      path: 'debt-rescue',
      imgSrc: 'debtrescue.png',
      campaignMeta: {
        url: '',
        currentDate: {
          set: false,
          apiKey: 'date'
        },
        dateOfBirth: {
          set: false,
          apiKey: '' // value of dateOfBirth must live in controls as "dateOfBirth"
        }
      },
      integrationDocUrl: '',
      apiUrl: '', // see readme to run light-web-server, this will help you run your php locally
      inputs: [
        {
          name: 'name',
          label: 'First Name',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Firstname'
            }
          ]
        },
        {
          name: 'surname',
          label: 'Surname',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Surname'
            }
          ]
        },
        {
          name: 'tel',
          label: 'Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'altTel',
          label: 'Alternative Phone Number',
          type: 'input',
          inputType: 'number',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'minlength',
              message: 'Please enter valid Phone Number'
            },
            {
              validation: 'maxlength',
              message: 'Please enter valid Phone Number'
            }
          ]
        },
        {
          name: 'email',
          label: 'Email Address:',
          type: 'input',
          inputType: 'email',
          placeholder: '',
          validations: [
            {
              validation: 'required',
              message: 'Please enter valid Email Address'
            },
            {
              validation: 'email',
              message: 'Please enter valid Email Address'
            }
          ]
        },
        {
          name: 'agentName',
          label: 'Agent Name:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        },
        {
          name: 'comments',
          label: 'Comments:',
          type: 'input',
          inputType: 'text',
          placeholder: '',
          validations: [
          ]
        }
      ],
      sheetConfig: {
        applicationName: '',
        spreadSheetId: '',
        developerKey: '',
        writer: {
          sheetName: '',
          url: ''
        },
        reader: {
          sheetName: '',
          url: '',
          set: true
        }
      }
    },
  ]
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
