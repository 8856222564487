import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwCampaignTemplateComponent } from './campaign-template.component';
import { CampaignTemplateService } from './campaign-template.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { PwButtonModule } from '../../form-modules/pw-button/pw-button.module';
import { PwInputModule } from '../../form-modules/pw-input/pw-input.module';
import { PwDropdownModule } from '../../form-modules/pw-dropdown/pw-dropdown.module';
import { PwTextAreaModule } from '../../form-modules/pw-text-area/pw-text-area.module';



@NgModule({
  declarations: [
    PwCampaignTemplateComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PwButtonModule,
    PwInputModule,
    PwDropdownModule,
    PwTextAreaModule
  ],
  providers: [
    CampaignTemplateService,
  ],
  exports: [
    PwCampaignTemplateComponent
  ]
})
export class PwCampaignTemplateModule { }
