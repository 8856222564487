import { Injectable, Output, EventEmitter } from '@angular/core';
import { Routes } from '@angular/router';
import * as $ from 'jquery';

@Injectable({
  providedIn: 'root'
})
export class CampaignTemplateService {

  @Output() names: EventEmitter<any> = new EventEmitter();
  @Output() onSubmit: EventEmitter<any> = new EventEmitter();
  routes: Routes = [];

  constructor() { }

  readTemplateNames(sheetConfig: any) {
    $.post(sheetConfig.reader.url, {
      sheetName: sheetConfig.reader.sheetName,
      developerKey: sheetConfig.developerKey,
      spreadSheetId: sheetConfig.spreadSheetId,
      applicationName: sheetConfig.applicationName
    }, (response) => {
      this.names.emit(response);
    });
  }

  writeTemplateValues(data: any, sheetConfig: any) {
    $.post(sheetConfig.writer.url, {
      ...data,
      sheetName: sheetConfig.writer.sheetName,
      developerKey: sheetConfig.developerKey,
      spreadSheetId: sheetConfig.spreadSheetId,
      applicationName: sheetConfig.applicationName
    });
  }

  setRoutes(routes: any[], component: any) {
    routes.map((route) => {
      this.routes.push({path: route.path, component: component, data: route});
    });
  }

  getRoutes(): Routes {
    return this.routes;
  }

  submitClicked(data: any) {
    this.onSubmit.emit(data);
  }
}
