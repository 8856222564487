import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PwDatePickerComponent } from './pw-date-picker.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    PwDatePickerComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [
    PwDatePickerComponent
  ]
})
export class PwDatePickerModule { }
