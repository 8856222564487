import { Injectable } from '@angular/core';
import { Firestore, setDoc, doc, writeBatch, getDocs, query, collection, getDoc, updateDoc, increment, where } from '@angular/fire/firestore';
import { limit, onSnapshot, Query } from 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})
export class PwFirebaseService {
  initialCounter: number = 0;

  constructor(private firestore: Firestore) { }

  // https://stackoverflow.com/questions/67448430/firestore-check-if-a-key-exists-then-check-if-a-specific-field-in-the-same-coll
  saveRecord(data: any, collectionName: string) {
    return setDoc(doc(this.firestore, collectionName, data.id), data);
  }
  
  saveMultipleRecords(batchname: string, leads: any, collectionName: string) : any{
    let collectionRef = doc(this.firestore, collectionName, batchname);
    let batch = writeBatch(this.firestore);
    batch.set(collectionRef, leads);
    return batch.commit();
  }

  getCollectionSize(collectionName: string) {
    let documentRef = doc(this.firestore, 'counters', collectionName);
    return getDoc(documentRef);
  }

  getMtd(collectionName: string, currentDate: string, resultLimit?: number): Query {
    const collectionRef = collection(this.firestore, collectionName);
    let q: Query;
    if (resultLimit) {
      q = query(collectionRef,
        where("day", "<=", currentDate.split('/')[0]),
        where("month", "==", currentDate.split('/')[1]),
        where("year", "==", currentDate.split('/')[2]),
        limit(resultLimit || 50),
      );
    } else {
      q = query(collectionRef,
        where("day", "<=", currentDate.split('/')[0]),
        where("month", "==", currentDate.split('/')[1]),
        where("year", "==", currentDate.split('/')[2])
      );
    }
    return q;
  }

  updateRecords(){
    let collectionRef = collection(this.firestore, 'leads');
    let snapshot = getDocs(collectionRef);
    snapshot.then((e) => {
      e.forEach((record) => {
        let day, month, year;
        let date = record.data().date ? record.data().date : "05/03/2020";
        let updateData = {
          day: date.split('/')[0],
          month: date.split('/')[1],
          year: date.split('/')[2]
        }
        console.log(record.data());
        console.log(updateData);
        updateDoc(record.ref, updateData);
      });
    })
  }

  updateRecordDate(recordId: string){
    let docRef = doc(this.firestore, 'leads', recordId);
    let snapshot = getDoc(docRef);
    snapshot.then((e) => {
      console.log(e.data());
      let record = e.data();
      let day, month, year;
      day = record.date.split('/')[0]
      month = record.date.split('/')[1]
      year = record.date.split('/')[2]

      let updateData = {
        day: day,
        month: month,
        year: year
      }
      return updateDoc(docRef, updateData);
    });
  }

  setInitialCountForCollection(collectionName: string): void {
    let _query = query(collection(this.firestore, collectionName));
    let snapshot = getDocs(_query);
    snapshot.then((e) => {
      e.forEach(() => {
        this.initialCounter +=1;
      });
    }).finally(() => {
      setDoc(doc(this.firestore, 'counters', collectionName), {total: this.initialCounter});
    });
  }

  setInitialDocumentTypeCount(collectionName: string) {
    console.log(collectionName)
    let _query = query(collection(this.firestore, collectionName));
    let snapshot = getDocs(_query);
    let batches = []
    let defaultDate = '24/05/2022';

    snapshot.then((e) => {
      console.log(e)
      
      e.forEach((documentVal) => {
        console.log(documentVal.data());

        let dateComposition = {
          day: '',
          month: '',
          year: ''
        }
        let dateCompositionArr = [];
        
        console.log(dateComposition)

        if (documentVal.data()['date']) {
          dateCompositionArr = documentVal.data()['date'].split('/');
          dateComposition.day = dateCompositionArr[0];
          dateComposition.month = dateCompositionArr[1];
          dateComposition.year = dateCompositionArr[2];

          // setDoc(doc(this.firestore, collectionName, documentVal.data()['id']), {
          //   day: dateComposition.day,
          //   month: dateComposition.month,
          //   year: dateComposition.year
          // });
        } else {
          dateCompositionArr = defaultDate.split('/');
          dateComposition.day = dateCompositionArr[0];
          dateComposition.month = dateCompositionArr[1];
          dateComposition.year = dateCompositionArr[2];

          // setDoc(doc(this.firestore, collectionName, documentVal.data()['id']), {
          //   day: dateComposition.day,
          //   month: dateComposition.month,
          //   year: dateComposition.year,
          //   date: defaultDate
          // });
        }        
      })
    })
  }

  updateCount(collectionName: string, campaignName?: string) {
    let docRef, incrementData;
    if (campaignName) {
      docRef = doc(this.firestore, 'counters', collectionName, 'campaigns', campaignName);
      incrementData = { total: increment(1) };
    } else {
      docRef = doc(this.firestore, 'counters', collectionName);
      incrementData = { total: increment(1) };
    }
    return updateDoc(docRef, incrementData);
  }

  updateRecord(collectionName: string, recordId: string, data: any) {
    let docRef = doc(this.firestore, collectionName, recordId);
    return updateDoc(docRef, data);
  }

  getRecordsWithNoId(collectionName: string) {
    console.log(collectionName)
    let _query = query(collection(this.firestore, collectionName));
    let snapshot = getDocs(_query);
    let incorrectRecords = [];

    snapshot.then((e) => {
      console.log(e)
      
      e.forEach((documentVal) => {
        if (!documentVal.data()['id']) {
          console.log(documentVal.data())
          
          let obj = {
            id: documentVal.id,
            data: documentVal.data()
          }
          incorrectRecords.push(obj)
          console.log(incorrectRecords)
        }
      })
    });

    return incorrectRecords;
  }
}
