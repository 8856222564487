import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'pw-textarea',
  templateUrl: './pw-text-area.component.html',
  styleUrls: ['./pw-text-area.component.scss']
})
export class PwTextAreaComponent implements OnInit {

  @Input() data: any;
  @Input() parentFormGroup: FormGroup;
  @Input() formStatus: any;

  constructor() { }

  ngOnInit(): void {
  }

}
