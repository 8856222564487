import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { CampaignTemplateService } from './campaign-template.service';
import * as $ from 'jquery';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'campaign-template',
  templateUrl: './campaign-template.component.html',
  styleUrls: ['./campaign-template.component.scss']
})
export class PwCampaignTemplateComponent implements OnInit {

  @Input() data: any;
  success: boolean = false;
  campaignFormGroup: FormGroup;
  errorCount: number;
  validationMessages: any = {};
  formStatus: any = {
    formErrors: {},
    submitClicked: false
  };
  error: boolean;
  message: string;
  showMessage: boolean = false;
  messageTitle: string;
  messageBody: string;
  year: number = new Date().getFullYear()

  constructor(
    private formBuilder: FormBuilder,
    private campaignTemplateService: CampaignTemplateService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    if (!this.data) {
      this.route.data.subscribe((e) => { 
        this.data = e;
        if (this.data.inputs) {
          this.createDynamicForm(this.data);

          this.campaignFormGroup.valueChanges.subscribe((data) => {
            this.formIsValid();
          });

          if (this.data.sheetConfig.reader.set) {
            this.campaignTemplateService.readTemplateNames(this.data.sheetConfig);
            this.campaignTemplateService.names.subscribe((names) => {
              let namesArray = JSON.parse(names);

              this.data.inputs.map((input) => {
                if (input.name === 'agentName') {
                  input.options = [];
                  namesArray.map((nameArray, index) => {
                    input.options.push({name: 'agent-' + index, label: nameArray[0], value: nameArray[0]});
                  });
                }
              })
            });
          }
        }
      });
    } else {
      this.createDynamicForm(this.data);

      this.campaignFormGroup.valueChanges.subscribe((data) => {
        this.formIsValid();
      });
    }
  }

  createDynamicForm(data: any) {
    let inputs = data.inputs;
    let formGroupObj = {};

    inputs.map((input) => {
      let ctrl = new FormControl()
      ctrl = this.setCtrlValidator(ctrl, input);
      formGroupObj[input.name] = ctrl;
    });

    this.campaignFormGroup = this.formBuilder.group(formGroupObj);
  }

  getFormValues() {
    let values = {};
    Object.keys(this.campaignFormGroup.controls).map((key) => {
      if (typeof this.campaignFormGroup.controls[key].value === 'object') {
        values[key] = this.campaignFormGroup.controls[key].value?.value
      } else {
        values[key] = this.campaignFormGroup.controls[key].value
      }      
    });
    return values;
  }

  setCtrlValidator(ctrl: FormControl, inputData: any): FormControl {
    let control: FormControl = ctrl;
    let validatorsArr = [];
    this.validationMessages[inputData.name] = {};

    if (inputData.validations.length > 0) {
      inputData.validations.map((validationObj) => {
        if (validationObj.validation === 'required') {
          validatorsArr.push(Validators.required)
        }

        if (validationObj.validation === 'minlength') {
          validatorsArr.push(Validators.minLength(10))
        }

        if (validationObj.validation === 'maxlength') {
          validatorsArr.push(Validators.maxLength(10))
        }

        if (validationObj.validation === 'email') {
          validatorsArr.push(Validators.email);
        }

        control.setValidators(validatorsArr);
        this.validationMessages[inputData.name][validationObj.validation] = validationObj.message;
        this.formStatus['formErrors'][inputData.name] = '';
      })
    }
    return control;
  }

  setCampaignMeta(campaignMeta: any, values: any) {
    Object.keys(campaignMeta).map(metaKey => {
      if (metaKey === 'currentDate') {
        if (campaignMeta[metaKey].set) {
          values[campaignMeta[metaKey].apiKey] = this.getOptinDate();
          return;
        }
      } else {
        if (metaKey === 'dateOfBirth') {
          if (campaignMeta[metaKey].set) {
            values[campaignMeta[metaKey].apiKey] = this.formatDob(values.dateOfBirth);
            return;
          }
        } else {
          values[metaKey] = campaignMeta[metaKey];
        }
      }
      });
    return values;
  }

  getOptinDate() {
    let currentDate = new Date();
    let optinDate = moment(currentDate).format('DD/MM/YYYY hh:mm:ss');
    
    return optinDate;
  }

  formatDob(dob) {
    let dateObj = new Date(dob);
    let newDob = moment(dateObj).format('DD/MM/YYYY');

    return newDob;
  }

  submitLead() {
    if (this.campaignFormGroup.status !== 'VALID') {
      this.formStatus.submitClicked = true;
      this.formIsValid();
      return;
    }

    this.success = true;
    let _status: any;
    let _response: any;
    let values = this.getFormValues();
    values = this.setCampaignMeta(this.data.campaignMeta, values);

    $.post(this.data.apiUrl, {
      ...values
    }, (response, status) => {
      _status = status;
      _response = response;
      this.showMessage = true;
      let writeObj = this.getFormValues();
      writeObj['status'] = status;
      
      if (typeof response === 'object') {
        let resp = JSON.parse(response);
        
        if(resp.hasOwnProperty('leadUid')) {
          writeObj['leadUid'] = resp.leadUid;
        }

        if(resp.hasOwnProperty('leadId')) {
          writeObj['leadId'] = resp.leadId;
        }
      }

      if (status === "success") {
        this.messageTitle = "Lead Submitted!"
        this.messageBody = "Your details have been successfully submitted."

        this.campaignTemplateService.writeTemplateValues({
          ...writeObj
        }, this.data.sheetConfig);
      } else {
        if (status === "error") {
          this.messageTitle = "An error has Occured!"
          this.messageBody = "Please try again and if the error persists, please contact your system administrator.";

          this.campaignTemplateService.writeTemplateValues({
            ...writeObj
          }, this.data.sheetConfig);
        }
      }
    });

    
    this.campaignTemplateService.submitClicked({
      data: values,
      submitClicked: true,
      submitStatus: _status,
      submitResponse: _response
    });
  }

  formIsValid() {
    if (!this.campaignFormGroup) {
      return;
    }

    const form = this.campaignFormGroup;
    this.errorCount = 0;

    for (const field in this.formStatus['formErrors']) {
      this.formStatus['formErrors'][field] = '';
      const control = form.get(field);

      if (control && control.dirty && !control.valid) {
        const messages = this.validationMessages[field];
        
        for (const key in control.errors) {
          this.formStatus['formErrors'][field] += messages[key] + ' ';
          this.errorCount++;
        }

      }
    }
  }

  resetForm() {
    this.campaignFormGroup.reset();
    this.showMessage = false;
  }
}
